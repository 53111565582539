import styled from 'styled-components'

export const StyledWalletIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: ${(props) => `1px solid ${props.walletBorderColorMobile}`};
  border-radius: 8.3px;
  padding: 3px 5px;
  height: 38px;
  margin: 0 6px;
  background-color: ${(props) => props.walletBgColorMobile};

  .icon-container {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 0;
    height: 28px;
    margin-left: 0;
    .icon-image {
      margin-top: -3px;
      width: 28px;
      height: 28px;
    }
  }
  .wallet-points-container {
    width: max-content;
    display: flex;
    align-items: flex-end;
    .wallet-points-text {
      color: ${(props) => props.walletTextColorMobile};
      font-weight: var(--brand-font-weight-medium);
      line-height: 15px;
      font-size: 15px;
      padding-top: 4px;
    }
  }

  @media (min-width: 768px) {
    background-color: ${(props) => props.walletBgColorDesktop};
    height: 38px;
    border-radius: 200px;
    padding: 3px 7px;
    border: ${(props) => `1px solid ${props.walletBorderColorDesktop}`};
    .wallet-points-container {
      border: 1px solid red;
      padding: 3px 8px;
      border-radius: 200px;
      background-color: ${(props) => props.walletAmountBgColor};
      .wallet-points-text {
        color: ${(props) => props.walletTextColorDesktop};
      }
    }
  }
`

export const IconsMenuContainer = styled.div`
  display: flex;

  @media (min-width: 768px) {
    align-items: center;
  }

  .icon-container {
    padding-left: 0.75rem;
    cursor: pointer;
    height: 40px;
    width: 40px;
    position: relative;
    padding: 6px;
  }

  .icon-image {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .tag-image {
    height: 16px;
    width: 16px;
  }
  .tag-image-wrap,
  .cart-badge {
    height: 16px;
    width: 16px;
    position: absolute;
    top: -2px;
    right: -4px;
    border-radius: 50%;
    background-color: var(--brand-primary-yellow-color);
    color: var(--brand-primary-black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: var(--brand-font-family-text);
    font-weight: 500;
  }

  @media (min-width: 768px) {
    margin-right: 32px;
    .icon-container {
      width: 40px;
      padding-left: 0;
      margin-left: 12px;
    }
  }
`
