import {
  useAction,
  useAuth,
  useCartStore,
  useConfigStore,
  useLoadingStore,
  useUserStore,
} from '@mosaic-wellness/redux-action-library'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useAddRepeatUserCookie} from 'src/hooks/useAddRepeatUserCookie'
import {useToggleBodyScroll} from 'src/hooks/useToggleBodyScroll'
import {NAVBAR_ANALYTICS, NAVBAR_GENERAL} from 'src/utils/constants/navbar'
import {SCREEN_PATH_NAMES} from 'src/utils/constants/screenPathNames'
import Navbar from 'src/components/shared/src/ComponentsV2/common/NavbarV2'
import {useGenericCta, useTruecallerLogin} from 'src/hooks'
import TruecallerLoading from '../shared/src/Screens/Checkout/TruecallerLoading'
import {useRouter} from 'next/router'
import {useAppConfigContext} from 'src/context/AppConfigContext'
import {getWhatsappLink} from 'src/utils/getWhatsappLink'
import {localStorageMethods} from 'src/utils'

const Nav = ({
  navState,
  showHeader = true,
  showSearchModal = false,
  handleShowSearchModal,
}: {
  navState: any
  showHeader: boolean
  showSearchModal: boolean
  handleShowSearchModal: any
  consultationNudgeData: any
  pageRegEx: any
}) => {
  const {rightMenuOptions = []} = navState || {}
  const searchInOptions = useMemo(() => {
    return rightMenuOptions.find((option: any) => option.label === 'search')
  }, [rightMenuOptions])
  const {pageRegEx = ''} = searchInOptions || {}
  const loading = useLoadingStore()
  const [appConfigContextState] = useAppConfigContext()
  const {whatsappConfig} = appConfigContextState
  const {analytics} = useConfigStore()
  const {user} = useUserStore()
  const {isLoggedIn, repeatUser = false} = user
  const {navigateTo} = useAction()
  const {
    actions: {setShowLoginModal},
  } = useAuth()
  const {handleCta} = useGenericCta()
  const {disableBodyScroll, enableBodyScroll} = useToggleBodyScroll()
  const {cart, refreshCart} = useCartStore()
  const {isUserHydration} = loading
  const {
    state: {isTruecallerEnabled, isPollingInProgress},
    actions: {triggerTruecallerLogin},
  } = useTruecallerLogin({
    triggerNormalLoginCallback: () =>
      setShowLoginModal({
        isOpen: true,
        triggeredFrom: '',
      }),
    source: 'generic',
  })

  useAddRepeatUserCookie(isUserHydration, isLoggedIn, repeatUser)

  const cartItemCount = useMemo(() => {
    return cart.totalItems ? cart.totalItems : 0
  }, [cart])

  useEffect(() => {
    if (!isUserHydration) {
      refreshCart(true)
    }
  }, [isUserHydration, refreshCart])

  const handleNavigateTo = useCallback(
    (navItem, isMobile) => {
      if (navItem.action === 'LOGOUT') {
        handleCta(navItem)
        return
      }
      if (navItem.link) {
        if (navItem.storeRefAndNav)
          localStorageMethods.set({
            keyName: 'urlPostAction',
            value: window.location.href,
          })

        if (
          navItem.link.indexOf(NAVBAR_GENERAL.HTTP) === 0 ||
          navItem.doFullPageNav
        ) {
          window.location.href = navItem.link
        } else {
          navigateTo(navItem.link)
        }
      }
      analytics.trigger(NAVBAR_ANALYTICS.MENULINK_CLICKED, {
        ...navItem,
        location: isMobile
          ? NAVBAR_ANALYTICS.HAMBURGER_MENU
          : NAVBAR_ANALYTICS.NAVBAR,
      })
    },
    [analytics, navigateTo, handleCta]
  )

  const handleHamburgerClick = useCallback(() => {
    analytics.trigger(NAVBAR_ANALYTICS.HAMBURGER_CLICK)
  }, [analytics])

  const navbarActions = useCallback(
    (icon) => {
      const {action} = icon
      switch (action) {
        case NAVBAR_GENERAL.PROFILE:
        case NAVBAR_GENERAL.LOGIN:
          if (isLoggedIn) {
            if (icon.link) {
              navigateTo(icon.link)
            } else {
              navigateTo(SCREEN_PATH_NAMES.MY_PROFILE)
            }
          } else {
            if (isTruecallerEnabled) {
              triggerTruecallerLogin()
            } else {
              setShowLoginModal({isOpen: true, triggeredFrom: ''})
            }
          }
          break
        case NAVBAR_GENERAL.SEARCH:
          analytics.trigger('searchClicked', {})
          handleShowSearchModal()
          disableBodyScroll()
          break

        case NAVBAR_GENERAL.SPA_LINK:
        case NAVBAR_GENERAL.LINK:
        case NAVBAR_GENERAL.STORE_REF_AND_NAV:
          handleCta(icon)
          break
        default:
          navigateTo(SCREEN_PATH_NAMES.HOME)
      }
    },
    [
      isLoggedIn,
      handleCta,
      navigateTo,
      analytics,
      disableBodyScroll,
      isTruecallerEnabled,
      triggerTruecallerLogin,
      handleShowSearchModal,
      setShowLoginModal,
    ]
  )

  const {asPath} = useRouter()

  const showCallOutOnRoute = useMemo(() => {
    if (!asPath || !pageRegEx) {
      return false
    }
    const regExForShowingCallout = new RegExp(pageRegEx)

    const [path] = asPath.split('?')
    return regExForShowingCallout.test(path)
  }, [asPath, pageRegEx])
  const whatsappData = useMemo(() => {
    const {isHidden = true} = whatsappConfig || {}
    const whatsappLink = isHidden ? '' : getWhatsappLink(whatsappConfig)
    return {
      whatsappLink,
      isHidden,
    }
  }, [whatsappConfig])

  if (!showHeader) {
    return null
  }

  return (
    <>
      <Navbar
        showSearchBar={showCallOutOnRoute}
        user={user}
        navData={navState}
        handleNavigateTo={handleNavigateTo}
        onHamburgerClick={handleHamburgerClick}
        cartItemCount={cartItemCount}
        navbarActions={navbarActions}
        disableBodyScroll={disableBodyScroll}
        enableBodyScroll={enableBodyScroll}
        showSearchModal={showSearchModal}
        whatsappData={whatsappData}
      />
      {isPollingInProgress && <TruecallerLoading />}
    </>
  )
}

export default Nav
