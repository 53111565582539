import styled from 'styled-components'

export const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 6;
  transition: all 500ms ease-in-out;

  .linksWrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--brand-primary-white-color);
  }

  .linksWrapper-inner {
    width: 100%;
    height: calc(100% - 85px);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 20px;
    padding-bottom: 100px;
    list-style: none;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .linkItem {
    max-width: 100%;
    display: flex;
    color: var(--brand-secondary-text-color);
    position: relative;
    align-items: center;
  }

  .link {
    width: 100%;
    font-family: var(--brand-font-family);
    font-size: 15px;
    line-height: 16px;
    font-weight: var(--brand-font-weight-bold);
    font-style: normal;
    display: flex;
    align-items: center;
    padding-bottom: 26px;
  }

  .tag-image {
    height: 17px;
    width: auto;
    object-fit: contain;
    margin-left: 5px;
  }
`
