import styled from 'styled-components'

export const StyledNavHeader = styled.div`
  width: 100%;
  box-shadow: 0px 5px 20px var(--brand-primary-black-light-color);
`

export const NavbarContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.fontColor};
  z-index: 6;
  position: sticky;
  top: 0;
  ${(props) =>
    !props.isSearchBarShown &&
    `box-shadow: 0px 5px 20px var(--brand-primary-black-light-color)`};
  &.fixed {
    position: fixed;
  }

  .nav-top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  .nav-top-section {
    height: ${(props) => (props.isMobile ? '50px' : '60px')};
    background-color: ${(props) => props.backgroundColor};
    z-index: 2;
  }
  .bottom-nudge-section {
    height: 34px;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    z-index: 999;
  }

  .left-section {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }

  .middle-section {
    display: flex;
    flex: 1 1;
    justify-content: center;
    height: 100%;
  }

  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: 8px;
  }
`

export const StyledSearchWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  ${(props) =>
    props.isSearchBarShown &&
    `box-shadow: 0px 5px 20px var(--brand-primary-black-light-color)`};
  ${(props) =>
    props.isSearchBarSticky &&
    `position: sticky;
     z-index: 6;
     top: 45px;
     box-shadow: none;
  `};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
`

export const StyledNudge = styled.div`
  position: sticky;
  top: ${(props) => (props.isMobile ? '50px' : '60px')};
  z-index: 3;
`
