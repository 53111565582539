import styled from 'styled-components'

export const HamburgerWarpper = styled.div`
  display: inline-flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  .bar1,
  .bar2,
  .bar3 {
    width: 16px;
    height: 2px;
    background-color: ${(props) => props.fontColor};
    margin: 2px 0;
    transition: 0.2s ease-in-out;
  }

  ${(props) =>
    props.isOpen &&
    `
      .bar1 {
        -webkit-transform: rotate(-45deg) translate(-2px, 6px);
        transform: rotate(-45deg) translate(-2px, 6px);
      }
      .bar2 {opacity: 0;}
      .bar3 {
        -webkit-transform: rotate(45deg) translate(-2px, -7px);
        transform: rotate(45deg) translate(-2px, -7px);
      }
  `}

  padding-left: 8px;
  transition: 0.5s ease-in-out;
`
