import noop from 'lodash/noop'
import React, {useCallback, useMemo} from 'react'
import Hamburger from '../Hamburger'
import MobileDropdown from '../MobileDropdown'
import UserMenu from '../UserMenu'
import {NavLinksContainer} from './styles'
import {getLinkToRender} from '../../../../utils'

function MobileNavLinks({
  headerLinks = [],
  user = {},
  handleMenuItemClicked = noop,
  handleLogin = noop,
  fontColor = '#000000',
  hamburgerOpen = false,
  toggleHamburger = noop,
}) {
  const {isLoggedIn = false} = user
  const handleNavigation = useCallback((e, item) => {
    e.preventDefault()
    handleMenuItemClicked(item)
  }, [])

  const filteredHeaderLinks = useMemo(
    () => headerLinks.filter((item) => !item.hiddenOnMobile),
    [headerLinks]
  )
  return (
    <NavLinksContainer className="mobile-nav-links-container">
      <Hamburger
        isOpen={hamburgerOpen}
        toggle={toggleHamburger}
        fontColor={fontColor}
      />
      {hamburgerOpen && (
        <div className="linksWrapper">
          <ul className="linksWrapper-inner">
            {filteredHeaderLinks.map((item) => {
              const {
                link = '',
                type = '',
                label = '',
                leadingIcon = '',
                loggedInLabel = '',
                dropdown = '',
                tagImage = '',
                hasImage = false,
                isOpenOnMobile = false,
              } = item

              if (type === 'AUTH') {
                return (
                  <UserMenu
                    user={user}
                    onLoginClick={handleLogin}
                    loggedInLabel={loggedInLabel}
                    label={label}
                    leadingIcon={leadingIcon}
                  >
                    {({customLabel}) => {
                      return (
                        <MobileDropdown
                          dropdownItems={dropdown}
                          // label={label}
                          customLabel={customLabel}
                          key={label}
                          handleMenuItemClicked={handleMenuItemClicked}
                          hasImage={hasImage}
                          isOpenOnMobile={isOpenOnMobile}
                        />
                      )
                    }}
                  </UserMenu>
                )
              }
              if (link) {
                const linkToRender = getLinkToRender(link)
                return (
                  <li key={label} className="linkItem">
                    <a
                      className="link"
                      href={linkToRender}
                      onClick={(e) => {
                        handleNavigation(e, item)
                      }}
                    >
                      {label}
                      {tagImage && <img className="tag-image" src={tagImage} />}
                    </a>
                  </li>
                )
              }

              if (dropdown) {
                return (
                  <MobileDropdown
                    dropdownItems={dropdown}
                    label={label}
                    key={label}
                    handleMenuItemClicked={handleMenuItemClicked}
                    hasImage={hasImage}
                    isOpenOnMobile={isOpenOnMobile}
                  />
                )
              }
              return null
            })}
          </ul>
        </div>
      )}
    </NavLinksContainer>
  )
}

export default React.memo(MobileNavLinks)
